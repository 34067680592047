/*
 * Home Page Javascript
 */

$(document).on('turbolinks:load', function () {

  // remove shadow when page loaded in home page
  $('.cb-home .cb-navbar').removeClass('shadow-sm')

  // transition effect for navbar
  $(window).on('scroll', function () {
    if ($(this).scrollTop() > 50) {
      $('.cb-home .cb-navbar').addClass('bg-white shadow-sm')
    } else {
      $('.cb-home .cb-navbar').removeClass('bg-white shadow-sm')
    }
  })

})
