/*
 * Clubs & Club Page Javascript
 */

$(document).on('turbolinks:load', function () {

  // toggle filter button
  $('.cb-clubs .cb-filter-btn').off('click').on('click', function () {
    $(this).toggleClass('show hide')
  })

  // fix cart button when scrolling
  $(window).on('scroll', function () {
    if ($(this).scrollTop() > 200) {
      $('.cb-club-detail .cb-club-cart-container').css('display', 'block')
    } else {
      $('.cb-club-detail .cb-club-cart-container').css('display', 'none')
    }
  })

})
