/*
 * Sidebar Component Javascript
 */

$(document).on('turbolinks:load', function () {

  // click mobile menu button
  $('#mobileMenuBtn').off('click').on('click', function () {

    // slide toggle mobile menu form with opacity
    $('#mobileSidebar').animate({
      opacity: 'toggle',
      left: '+=80%'
    }, 300)

    // fade toggle overlay
    $('main .cb-overlay').fadeToggle(300)

    // overflow toggle body
    $('body').toggleClass('overflow-hidden');
  })

  // click sidebar close button (only for hiding)
  $('#mobileSidebarCloseBtn').off('click').on('click', function () {

    // slide toggle search form with opacity
    $('#mobileSidebar').animate({
      opacity: 'toggle',
      left: '-=80%'
    }, 300)

    // fade toggle overlay
    $('main .cb-overlay').fadeToggle(300)

    // overflow toggle body
    $('body').toggleClass('overflow-hidden');
  })

  // click backdrop (only for hiding)
  $('main .cb-overlay').off('click').on('click', function () {

    // fade toggle backdrop
    $(this).fadeToggle(300)

    // slide toggle mobile search form
    $('#mobileSidebar').animate({
      opacity: 'toggle',
      left: '-=80%'
    }, 300)
    
    // overflow toggle body
    $('body').toggleClass('overflow-hidden');
  })

})

$(document).on('turbolinks:request-start', function () {
  
  // initialize menu position
  $('#mobileSidebar').css('left', '-80%');

  // initialize overlay
  $('main .cb-overlay').css('display', 'none')

})
