/*
 * Header Component Javascript
 */

$(document).on('turbolinks:load', function () {

  // click mobile search button
  $('#mobileSearchBtn').off('click').on('click', function () {

    // slide toggle mobile search form with opacity
    $('#mobileSearchForm').animate({
      opacity: 'toggle',
      height: 'toggle'
    }, 300)

    // fade toggle overlay
    $('header .cb-overlay').fadeToggle(300)

    // overflow toggle body
    $('body').toggleClass('overflow-hidden');
  })

  // click mobile search form close button (only for hiding)
  $('#mobileSearchFormCloseBtn').off('click').on('click', function () {

    // slide toggle search form with opacity
    $('#mobileSearchForm').animate({
      opacity: 'toggle',
      height: 'toggle'
    }, 300)

    // fade toggle overlay
    $('header .cb-overlay').fadeToggle(300)

    // overflow toggle body
    $('body').toggleClass('overflow-hidden');
  })

  // click backdrop (only for hiding)
  $('header .cb-overlay').off('click').on('click', function () {

    // fade toggle backdrop
    $(this).fadeToggle(300)

    // slide toggle mobile search form
    $('#mobileSearchForm').animate({
      opacity: 'toggle',
      height: 'toggle'
    }, 300)
    
    // overflow toggle body
    $('body').toggleClass('overflow-hidden');
  })

  // replace search form with turbolinks
  $(document).off('submit', '.cb-search-form').on('submit', '.cb-search-form', function (e) {
    Turbolinks.visit(this.action + (this.action.indexOf('?') == -1 ? '?' : '&') + $(this).serialize())
    return false
  })

})
