/*
 * Filter Component Javascript
 */

// function for detecting model filter change
function detectModelFilterChange() {
  if ($('.cb-maker-filter .cb-filter-dropdown').find('input[type="checkbox"]:checked').length > 0
    || $('.cb-brand-filter .cb-filter-dropdown').find('input[type="checkbox"]:checked').length > 0) {
    $('.cb-model-filter .cb-filter-dropdown').removeClass('show').addClass('hide')
    $('.cb-model-filter .cb-filter-dropdown-btn').removeClass('disabled')
    $('.cb-model-filter .cb-filter-dropdown-content').removeClass('disabled')
    $('.cb-model-filter .cb-filter-dropdown-btn span').text('モデルを選択する')
  } else {
    $('.cb-model-filter .cb-filter-dropdown').removeClass('show').addClass('hide')
    $('.cb-model-filter .cb-filter-dropdown-btn').addClass('disabled')
    $('.cb-model-filter .cb-filter-dropdown-content').addClass('disabled')
    $('.cb-model-filter .cb-badge-box').empty()
    $('.cb-model-filter .cb-filter-dropdown-btn span').text('メーカーかブランドを選択してください')
  }
}

// function for detecting filter checkbox change
function detectFilterCheckboxChange(type) {
  // append badges from checkbox
  $(`.cb-${type}-filter .cb-filter-dropdown-content .btn`).off('click').on('click', function () {
    if ($(this).parents('.cb-filter-dropdown').find('input[type="checkbox"]:checked').length > 10) {
      $(this).parents('.cb-filter-dropdown').find('.cb-filter-alert').show()
      var _this = $(this)
      setTimeout(function () {
        _this.parents('.cb-filter-dropdown').find('.cb-filter-alert').hide()
      }, 5000)
    } else {
      $(this).parents('.cb-filter-dropdown').find('.cb-badge-box').empty()
      $.each($(this).parents('.cb-filter-dropdown').find('input[type="checkbox"]:checked'), function () {
        $(this).parents('.cb-filter-dropdown').find('.cb-badge-box').append(`
          <button type="button" class="cb-filter-badge-btn btn btn-sm btn-primary shadow-sm mr-2 py-0" data-id="${$(this).data('id')}">` +
          `&times; ${$(this).next().text()}` +
          `</button>
          <input type="hidden" name="${type}s[]" value="${$(this).data('id')}">
        `)
      })
      if (type === 'maker') {
        updateBrands()
        updateModels()
      }
      if (type === 'brand') {
        updateModels()
      }
      if (type !== 'model') {
        detectModelFilterChange()
      }
      $(`.cb-${type}-filter .cb-filter-badge-btn`).off('click').on('click', function () {
        $(this).parents('.cb-filter-dropdown').find(`#${type}Checkbox${$(this).data('id')}`).prop('checked', false)
        $(this).next().remove()
        $(this).remove();
        if (type === 'maker') {
          updateBrands()
          updateModels()
        }
        if (type === 'brand') {
          updateModels()
        }
        if (type !== 'model') {
          detectModelFilterChange()
        }
      })
      $(this).parents('.cb-filter-dropdown').toggleClass('show hide')
    }
  })
  // remove badges and uncheck
  $(`.cb-${type}-filter .cb-filter-badge-btn`).off('click').on('click', function () {
    $(this).parents('.cb-filter-dropdown').find(`#${type}Checkbox${$(this).data('id')}`).prop('checked', false)
    $(this).next().remove()
    $(this).remove();
    if (type === 'maker') {
      updateBrands()
      updateModels()
    }
    if (type === 'brand') {
      updateModels()
    }
    if (type !== 'model') {
      detectModelFilterChange()
    }
  })
}

// function for update brands with makers
function updateBrands() {
  var makers = []
  $('input[name="makers[]"]').each(function () {
    makers.push($(this).val())
  })
  $.ajax({
    method: 'GET',
    url: '/brands',
    data: {
      makers
    },
    success: function (brands) {
      $('.cb-brand-filter .cb-badge-box').empty()
      $('.cb-brand-filter .tab-content .tab-pane').each(function () {
        var _this = $(this)
        var syllabaryID = $(this).attr('id').substring(14)
        var syllabaryBrands = brands.filter(function (b) {
          return b.syllabary_id && b.syllabary_id.toString() === syllabaryID
        })
        $(this).empty()
        if (syllabaryBrands && syllabaryBrands.length > 0) {
          $(this).append(`
            <div class="row row-cols-2 row-cols-lg-4 p-2 p-lg-3"></div>
          `)
          syllabaryBrands.forEach(function (b) {
            _this.children('.row').append(`
              <div class="col">
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    id="brandCheckbox${b.id}"
                    class="custom-control-input"
                    data-id="${b.id}"
                  >
                  <label for="brandCheckbox${b.id}" class="cb-h7 custom-control-label">
                    ${b.name}
                  </label>
                </div>
              </div>
            `)
          })
        } else {
          $(this).append(`
            <p class="cb-h7 text-center p-2 p-lg-3">ブランドが見つかりません</p>
          `)
        }
      })
    }
  })
}

// function for update models with makers
function updateModels() {
  var makers = []
  var brands = []
  $('input[name="makers[]"]').each(function () {
    makers.push($(this).val())
  })
  $('input[name="brands[]"]').each(function () {
    brands.push($(this).val())
  })
  $.ajax({
    method: 'GET',
    url: '/models',
    data: {
      makers,
      brands
    },
    success: function (models) {
      $('.cb-model-filter .cb-badge-box').empty()
      $('.cb-model-filter .tab-content .tab-pane').each(function () {
        var _this = $(this)
        var syllabaryID = $(this).attr('id').substring(14)
        var syllabaryModels = models.filter(function (m) {
          return m.syllabary_id && m.syllabary_id.toString() === syllabaryID
        })
        $(this).empty()
        if (syllabaryModels && syllabaryModels.length > 0) {
          $(this).append(`
            <div class="row row-cols-2 row-cols-lg-4 p-2 p-lg-3"></div>
          `)
          syllabaryModels.forEach(function (m) {
            _this.children('.row').append(`
              <div class="col">
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    id="modelCheckbox${m.id}"
                    class="custom-control-input"
                    data-id="${m.id}"
                  >
                  <label for="modelCheckbox${m.id}" class="cb-h7 custom-control-label">
                    ${m.name}
                  </label>
                </div>
              </div>
            `)
          })
        } else {
          $(this).append(`
            <p class="cb-h7 text-center p-2 p-lg-3">モデルが見つかりません</p>
          `)
        }
      })
    }
  })
}

$(document).on('turbolinks:load', function () {

  // toggle filter dropdown content
  $('.cb-filter-dropdown-btn').off('click').on('click', function () {
    $(this).parents('.cb-filter-dropdown').toggleClass('show hide')
    $(this).next().find('.nav-link').removeClass('active')
    $(this).next().find('.nav-item:first-child .nav-link').addClass('active')
    $(this).next().find('.tab-pane').removeClass('show active')
    $(this).next().find('.tab-pane:first-child').addClass('show active')
  })

  // detect filter checkbox change
  detectFilterCheckboxChange('maker')
  detectFilterCheckboxChange('brand')
  detectFilterCheckboxChange('model')

  // initialize range slider
  $('.cb-range-slider').slider({
    range: true,
    min: 0,
    max: 100000,
    values: [0, 100000],
    slide: function (event, ui) {
      $(this).children('.ui-slider-handle').first().text('¥ ' + ui.values[0].toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))
      $(this).children('.ui-slider-handle').last().text('¥ ' + ui.values[1].toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))
      $('input[name="price_from"]').val(ui.values[0])
      $('input[name="price_to"]').val(ui.values[1])
    },
    create: function (event, ui) {
      $(this).children('.ui-slider-handle').first().text('¥ ' + $('input[name="price_from"]').val().toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))
      $(this).children('.ui-slider-handle').last().text('¥ ' + $('input[name="price_to"]').val().toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))
      $(this).slider('values', [$('input[name="price_from"]').val(), $('input[name="price_to"]').val()])
    }
  })

  // replace filter form with turbolinks
  $(document).off('submit', '.cb-filter').on('submit', '.cb-filter', function (e) {
    Turbolinks.visit(this.action + (this.action.indexOf('?') == -1 ? '?' : '&') + $(this).serialize())
    return false
  })

})

